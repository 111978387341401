<template>
  <div id="app">
		<Nav v-if="$store.getters.isReady"/>
    <router-view/>
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQrcode, faSackDollar, faCheckCircle, faTimesCircle, faUser, faKey, faCalendarAlt, faSpinner, faChartBar, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'

library.add(faQrcode, faSackDollar, faCheckCircle, faTimesCircle, faUser, faKey, faCalendarAlt, faSpinner, faChartBar, faTrashAlt)

axios.interceptors.response.use(response => {
  return response
}, error => {
  try {
    if (error.response.status === 401) {
      this.$store.dispatch('logout')
    }
    return error
  } catch {
    return error
  }
})

export default {
  name: 'App',
  components: {
		Nav
  },
  created() {
    window.addEventListener('beforeunload', () => {
      this.$store.dispatch('logout')
    }, false)
  }
}
</script>

<style>
:root {
  --main-bg-color: #272727;
  --secondary-bg-color: #393939;
  --tertiary-bg-color: #cbcbcb;
  --hover-bg-color: #434343;

  --main-text-color: white;
  --secondary-text-color: #333333;
  --tertiary-text-color: #e0e0e0;
  --hover-text-color: #a4a4a4;
}

html, body {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.mainContainer {
  position: absolute;
  top: 100px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  background-color: var(--secondary-bg-color);
}

a {
  color: var(--main-text-color);
  text-decoration: underline;
}

a:hover {
  color: var(--hover-text-color);
  cursor: pointer;
}

#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0 auto;
}

.confirmCancelButtonsWrapper {
	display: flex;
	height: 2.5em;
	width: 120px;
	background-color: var(--main-bg-color);
	justify-content: space-evenly;
	align-items: center;
	box-sizing: border-box;
	padding-left: 5px;
	padding-right: 5px;
	border-radius: 5px;
	border: 1px solid black;
}

.button {
	cursor: pointer;
}

.button:hover {
	-webkit-transform: scale(1.1);
}

.inputWrapper {
	display: flex;
	height: 2.5em;
	background-color: var(--main-bg-color);
	justify-content: right;
	align-items: center;
	box-sizing: border-box;
	padding-left: 5px;
	padding-right: 5px;
	border-radius: 5px;
	border: 1px solid black;
	margin-bottom: 10px;
}

input,
select {
	display: flex;
	flex-grow: 1;
	outline: none;
	border: none;
	background-color: var(--main-bg-color);
	text-align: right;
	margin-right: 5px;
	box-sizing: border-box;
	padding-right: 10px;
	font-size: 1.25em;
	height: 100%;
	width: 100%;
	color: var(--main-text-color);
  cursor: pointer;
}

.vdp-datepicker {
  flex-grow: 1 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

button {
	margin: 0 auto;
	background-color: var(--tertiary-bg-color);
	border-radius: 5px;
	font-size: 1.2em;
	padding: 5px;
  cursor: pointer;
}

.calendar {
  background: var(--main-bg-color) !important;
  color: var(--main-text-color) !important;
}

</style>
