<template>
  <div class="mainContainer">
    <div class="generatorType">
      <div class="title">Série de bon à vendre sur place</div>
      <div class="details">Génère une série de bon à imprimer, pour vendre sur place. Les bons doivent être activés à la vente. Le montant est optionnel.</div>
      <div class="inputWrapper">
        <span>Créer</span>
        <input type="number" min="3" step="3" v-model="amount">
        <span>bons</span>
      </div>
      <div class="inputWrapper">
        <font-awesome-icon :icon="['fad', 'sack-dollar']" size="2x"/>
        <input type="number" title="Optionnel" v-model="multipleVouchersValue">
        <span>CHF</span>
      </div>
      <div class="confirmCancelButtonsWrapper" style="margin: 0 auto;" @click="createMultiple">
        <font-awesome-icon :icon="['fad', 'check-circle']" size="2x" class="button" title="Créer!" v-if="!waiting"/>
        <font-awesome-icon :icon="['fad', 'spinner']" pulse size="2x" class="button" title="Création en cours" v-else/>
      </div>
    </div>
    <div class="generatorType">
      <div class="title">Bon unique</div>
      <div class="details">Génère un bon unique d'un montant donné à la date de validité définie</div>
      <div class="inputWrapper">
        <font-awesome-icon :icon="['fad', 'sack-dollar']" size="2x"/>
        <input type="number" min="5" v-model="singleVoucherValue">
        <span>CHF</span>
      </div>
      <p class="inputWrapper">
        <span><font-awesome-icon :icon="['fad', 'calendar-alt']" size="2x"/></span>
        <datepicker v-model="validityDate" :language="fr" calendar-class="calendar"></datepicker>
      </p>
      <div class="confirmCancelButtonsWrapper" style="margin: 0 auto;" @click="createSingle">
        <font-awesome-icon :icon="['fad', 'check-circle']" size="2x" class="button" title="Créer!" v-if="!waiting"/>
        <font-awesome-icon :icon="['fad', 'spinner']" pulse size="2x" class="button" title="Création en cours" v-else/>
      </div>
    </div>
    <div class="generatorType">
      <div class="title">PDF disponibles</div>
      <div class="details">Liste tous les pdf disponibles</div>
      <div v-for="filename in pdfs" :key="filename" class="availablePdf">
        <span class="trash" title="Supprimer" @click="deleteFile(filename)"><font-awesome-icon :icon="['fad', 'trash-alt']"/></span>
        <a @click="openPdf(filename)">{{ toDate(filename) }}</a>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import Datepicker from 'vuejs-datepicker'
import fr from 'vuejs-datepicker/dist/locale'

export default {
  name: 'Create',
  components: {
    Datepicker
  },
  data: function() {
    return {
      baseUrl: axios.defaults.baseURL,
      pdfs: [],
      amount: 3,
      multipleVouchersValue: 0,
      singleVoucherValue: 25,
      waiting: false,
      validityDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      fr: fr
    }
  },
  onIdle() {
    this.$store.dispatch('logout')
  },
  async mounted() {
    await this.fetchPDFList()
  },
  methods: {
    toDate(filename) {
      filename = filename.substring(1, filename.length - 4)
      const date = new Date(parseInt(filename) * 1000)
      return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
    },
    deleteFile(filename) {
      let self = this
      axios.delete(`/pdf/${filename}`, {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      }).finally(() => {
        self.pdfs = self.pdfs.filter(item => item !== filename)
      })
    },
    async fetchPDFList() {
      let self = this
      await axios.get('/pdf', {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      }).then(response => {
        if (response.status === 200) {
          self.pdfs = response.data['_embedded']['pdf'][0]
        }
      })
    },
    async createMultiple() {
      let data = {
        'amount': this.amount,
        'value': this.multipleVouchersValue,
        'multiple': true
      }
      await this.create(data)
    },
    async createSingle() {
      let data = {
        'amount': this.amount,
        'value': this.singleVoucherValue,
        'validity': Math.round(Date.parse(this.validityDate) / 1000),
        'multiple': false
      }
      await this.create(data)
    },
    async create(data) {
      this.waiting = true
      let self = this
      await axios.post('/vouchers', data, {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      }).then(response => {
        if (response.status === 201) {
          self.openPdf(response.data.filename)
          self.fetchPDFList()
          self.$store.dispatch('getVouchers')
          self.waiting = false
        }
      })
    },
    openPdf(filename) {
      if (!filename.endsWith('.pdf')) {
        filename = `${filename}.pdf`
      }
      axios.get(`/pdf/${filename}`, {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`,
          'Accept': 'application/json'
        },
        responseType: 'arraybuffer'
      }).then(response => {
        let blob = new Blob([response.data], {type: 'application/pdf'})
        let url = window.URL.createObjectURL(blob)
        window.open(url, '_blank').focus()
      }).catch(reason => {
        console.log(reason)
      })
    }
  }
}
</script>

<style scoped>
  .inputWrapper {
    width: 50%;
    margin: 15px auto;
  }
  .generatorType {
    border-radius: 5px;
    width: 50%;
    margin: 15px auto;
    background-color: var(--tertiary-bg-color);
    box-sizing: border-box;
    padding: 15px;
  }
  .title {
    font-size: 1.5em;
    color: var(--secondary-text-color);
  }
  .details {
    font-style: italic;
    color: var(--secondary-text-color);
  }
  .availablePdf {
    display: flex;
    align-items: center;
    margin-top: 2px;
    width: 100%;
    background-color: var(--main-bg-color);
    height: 45px;
    min-height: 45px;
    box-sizing: border-box;
    padding: 10px;
  }
  .trash {
    font-size: 1.5em;
    margin-right: 15px;
    cursor: pointer;
  }
  .trash:hover {
    color: red;
  }
</style>
