<template>
  <div class="mainContainer">
    <div class="inputWrapper">
      <span><font-awesome-icon :icon="['fad', 'chart-bar']" size="2x"/></span>
      <select v-model="show" @change="fillData">
        <option value="annee">Année</option>
        <option value="mois">Mois</option>
        <option value="semaine">Semaine</option>
        <option value="date">Date</option>
        <option value="jour">Jour</option>
      </select>
    </div>
    <div class="chart">
      <chart v-if="loaded" :chart-data="dataCollection" :options="chartOptions"></chart>
    </div>
    <div class="stats" v-if="loaded">
      <div class="statsTitle">Rapports</div>
      <div class="statsTable" id="statsTable">
        <div class="statsRow">
          <div>Vente totale de bon</div>
          <div>{{ totalAmountSold }} bons pour {{ totalValueSold }} chf</div>
        </div>
        <div class="statsRow">
          <div>Bon utilisés</div>
          <div>{{ totalAmountUsed }} bons pour {{ totalValueUsed }} chf</div>
        </div>
        <div class="statsRow">
          <div>Bon hors date</div>
          <div>{{ totalAmountExpired }} bons pour {{ totalValueExpired }} chf</div>
        </div>
        <div class="statsRow">
          <div>Potentiels retours</div>
          <div>{{ totalAmountSold - totalAmountUsed }} bons pour {{ totalValueSold - totalValueUsed }} chf</div>
        </div>
        <div class="statsRow">
          <div>Potentiels retours sans expirés</div>
          <div>{{ totalAmountSold - totalAmountUsed - totalAmountExpired }} bons pour {{ totalValueSold - totalValueUsed - totalValueExpired }} chf</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from '@/components/Chart'

export default {
  name: 'Stats',
  components: {
    Chart
  },
  data: function() {
    return {
      totalAmountSold: 0,
      totalValueSold: 0,
      totalAmountExpired: 0,
      totalValueExpired: 0,
      totalAmountUsed: 0,
      totalValueUsed: 0,
      show: 'mois',
      loaded: false,
      dataCollection: null,
      graphData: {},
      yearData: {},
      monthData: {},
      weekData: {},
      dayData: {},
      dateData: {},
      days: {
        0: 'Dimanche',
        1: 'Lundi',
        2: 'Mardi',
        3: 'Mercredi',
        4: 'Jeudi',
        5: 'Vendredi',
        6: 'Samedi'
      },
      months: {
        0: 'Janvier',
        1: 'Février',
        2: 'Mars',
        3: 'Avril',
        4: 'Mai',
        5: 'Juin',
        6: 'Juillet',
        7: 'Août',
        8: 'Septembre',
        9: 'Octobre',
        10: 'Novembre',
        11: 'Décembre'
      },
      chartOptions: {
        legend: {
          display: true,
          position: 'top',
          labels: {
            fontColor: 'white',
            fontSize: 20
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: 'white',
              fontSize: 18,
              beginAtZero: true
            }
          }],
          xAxes: [{
            ticks: {
              fontColor: 'white',
              fontSize: 14,
              beginAtZero: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: 'white',
            anchor: 'end',
            clamp: true,
            align: 'end',
            rotation: -45,
            overlap: 'auto'
          }
        }
      }
    }
  },
  onIdle() {
    this.$store.dispatch('logout')
  },
  mounted() {
    let graphData = {}
    let yearData = {}
    let monthData = {}
    let weekData = {}
    let dayData = {}
    let dateData = {}

    let now = new Date()
    for (const voucher of Object.values(this.$store.getters.vouchers)) {
      if (voucher.sellDate != null) {
        this.totalAmountSold++
        this.totalValueSold += parseFloat(voucher.value)

        let dateObject = new Date(voucher.sellDate * 1000)
        let year = dateObject.getFullYear()
        let month = String(dateObject.getMonth()).padStart(2, '0')
        const week = String(dateObject.getWeek()).padStart(2, '0')
        const day = dateObject.getDay()
        const date = String(dateObject.getDate()).padStart(2, '0')
        if (year > now.getFullYear() || (year === now.getFullYear() && parseInt(month) > now.getMonth())) {
          console.warn(`Vente impossible car dans le future: ${voucher.code}`)
          if (year > now.getFullYear()) {
            console.warn("Correction de l'année sur l'année courante")
            year = now.getFullYear()
          } else {
            console.warn('Correction automatique impossible, erreur de mois')
            continue
          }
        }
        if (!{}.hasOwnProperty.call(yearData, year)) {
          yearData[year] = []
        }
        if (!{}.hasOwnProperty.call(monthData, month)) {
          monthData[month] = []
        }
        if (!{}.hasOwnProperty.call(weekData, week)) {
          weekData[week] = []
        }
        if (!{}.hasOwnProperty.call(dayData, day)) {
          dayData[day] = []
        }
        if (!{}.hasOwnProperty.call(dateData, date)) {
          dateData[date] = []
        }

        yearData[year].push(voucher)
        monthData[month].push(voucher)
        weekData[week].push(voucher)
        dayData[day].push(voucher)
        dateData[date].push(voucher)

        // graphData[ YEAR ][ MONTH ][ DAY ][ VOUCHERS ]
        if (!{}.hasOwnProperty.call(graphData, year)) {
          graphData[year] = {}
          graphData[year][month] = {}
          graphData[year][month][day] = [voucher]
        } else if (!{}.hasOwnProperty.call(graphData[year], month)) {
          graphData[year][month] = {}
          graphData[year][month][day] = [voucher]
        } else if (!{}.hasOwnProperty.call(graphData[year][month], day)) {
          graphData[year][month][day] = [voucher]
        }
      }

      if (voucher.balance > 0 && voucher.validDate < Math.floor(now.getTime()) / 1000) {
        this.totalValueExpired += parseFloat(voucher.balance)
        this.totalAmountExpired++
      }

      if (voucher.used !== '{}') {
        if (parseFloat(voucher.balance) <= 0) {
          this.totalValueUsed += parseFloat(voucher.value)
          this.totalAmountUsed++
        }
      }
    }
    this.graphData = graphData
    this.monthData = monthData
    this.weekData = weekData
    this.dayData = dayData
    this.dateData = dateData
    this.yearData = yearData
    this.fillData()
    this.loaded = true
  },
  methods: {
    increment: function(date, label) {
      if (this.show === 'mois' && this.months[date.getMonth()] === label) {
        return true
      } else if (this.show === 'semaine' && String(date.getWeek()).padStart(2, '0') === label) {
        return true
      } else if (this.show === 'jour' && this.days[date.getDay()] === label) {
        return true
      } else if (this.show === 'annee' && String(date.getFullYear()) === label) {
        return true
      } else if (this.show === 'date' && String(date.getDate()).padStart(2, '0') === label) {
        return true
      }
      return false
    },
    fillData: function() {
      let labels
      if (this.show === 'mois') {
        labels = Object.keys(this.monthData)
      } else if (this.show === 'semaine') {
        labels = Object.keys(this.weekData)
      } else if (this.show === 'jour') {
        labels = Object.keys(this.dayData)
      } else if (this.show === 'date') {
        labels = Object.keys(this.dateData)
      } else {
        labels = Object.keys(this.yearData)
      }

      labels.sort()
      if (this.show === 'jour') {
        for (const label of [...labels]) {
          labels.shift()
          labels.push(this.days[label])
        }
        let sunday = labels.shift()
        labels.push(sunday)
      } else if (this.show === 'mois') {
        for (const label of [...labels]) {
          labels.shift()
          labels.push(this.months[parseInt(label)])
        }
      }

      let sellData = []
      let usageData = []
      let sellValueData = []
      let usageValueData = []
      for (let label of labels) {
        let sellCount = 0
        let usageCount = 0
        let sellValue = 0
        let usageValue = 0
        for (const voucher of Object.values(this.$store.getters.vouchers)) {
          if (voucher.sellDate === null) {
            continue
          }
          let sellDate = new Date(voucher.sellDate * 1000)
          if (this.increment(sellDate, label)) {
            sellCount++
            sellValue += parseInt(voucher.value)
          }

          if (voucher.used === '{}') {
            continue
          }

          let usage = JSON.parse(voucher.used)
          for (const [date, amount] of Object.entries(usage)) {
            const usageDate = new Date(parseInt(date))
            if (this.increment(usageDate, label)) {
              usageCount++
              usageValue += parseFloat(amount)
            }
          }
        }
        sellData.push(sellCount)
        usageData.push(usageCount)
        sellValueData.push(sellValue)
        usageValueData.push(usageValue)
      }

      let dataset = [
        {
          label: 'Vente',
          backgroundColor: '#3374FF',
          data: sellData
        },
        {
          label: 'Encaissement',
          backgroundColor: '#33FF74',
          data: usageData
        },
        {
          label: 'Valeur vente',
          backgroundColor: '#ff6733',
          data: sellValueData,
          hidden: true
        },
        {
          label: 'Valeur encaissement',
          backgroundColor: '#006720',
          data: usageValueData,
          hidden: true
        }
      ]

      this.dataCollection = {
        labels: labels,
        datasets: dataset
      }
    }
  }
}
</script>

<style scoped>
 .chart {
   position: relative;
   width: 95%;
   margin: 50px auto;
 }
 .inputWrapper {
   position: fixed;
   z-index: 999;
   margin: 15px 50px;
   width: 300px;
 }
 .stats {
   margin-bottom: 25px;
 }
 .statsTitle {
   width: 100%;
   text-align: center;
   color: var(--main-text-color);
   font-size: 2em;
 }
 .statsTable {
   width: 500px;
   margin: 30px auto;
   display: table;
   border-collapse: collapse;
 }
 .statsRow {
   display: table-row;
 }
 .statsRow > div {
   display: table-cell;
   width: 50%;
   height: 30px;
 }
</style>
