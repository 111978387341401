<template>
  <div>
    <div class="mainContainer" id="mainContainer" v-if="isReady">
      <div class="tabContainer" v-show="activeTab === 0">
				<div class="scannerContainer">
					<qrcode-stream :track="paintBoundingBox" @init="onInit" :camera="cameraState" @decode="onQrCode" class="qrScanner">
						<div class="loadingIndicator" v-if="cameraLoading">
							Chargement...
						</div>
					</qrcode-stream>
				</div>
        <div class="inputWrapper" style="width: 500px; margin: 0 auto;">
					<span><font-awesome-icon :icon="['fad', 'qrcode']" size="2x"/></span>
          <input id="qrCodeInput" type="text" placeholder="Entrez le code manuellement" v-on:input="onQRInput" v-model="scanResult"/>
					<font-awesome-icon :icon="['fad', 'times-circle']" size="2x" class="button" @click="scanResult = ''" v-if="scanResult !== ''"/>
        </div>
        <div id="voucherInWork" class="scannedVoucher" v-if="voucher !== null">
					<div class="closeVoucher">
						<font-awesome-icon :icon="['fad', 'times-circle']" size="2x" class="button" @click="cancelVoucherEdit" title="annuler"/>
					</div>
          <div class="destroyVoucher">
            <font-awesome-icon :icon="['fad', 'trash-alt']" class="button" @click="askDestroyConfirm" title="supprimer" v-if="!confirmDestroy"/>
            <font-awesome-icon :icon="['fad', 'check-circle']" class="button" @click="destroyVoucher" title="confirmer" v-if="confirmDestroy"/>
          </div>
          <div class="voucherCode">{{ voucher.code }}</div>
          <div v-if="voucher.value !== null">
            <div class="voucherContent">
              <div class="flexColumn left">
                <p class="voucherInfoTitle">Solde</p>
                <p class="voucherInfo" id="voucherInfo">
                  <span :class="{deprecated: deduction !== 0}">{{ voucher.balance }} CHF</span>
                  <span v-if="deduction !== 0" style="margin-left: 10px;">{{ Math.max(voucher.balance - deduction, 0) }} CHF</span>
                  <span v-if="deduction !== 0 && voucher.balance - deduction < 0" style="margin-left: 10px; color: red;">! Encaisser {{ Math.abs(voucher.balance - deduction) }} CHF manquant !</span>
                </p>
              </div>
              <div class="flexColumn">
                <p class="voucherInfoTitleRight">Validité</p>
                <p class="voucherInfoRight" :class="{red: voucher.validDate > Date.now()}">{{ timestampToDate(voucher.sellDate) }} - {{ timestampToDate(voucher.validDate) }}</p>
                <p class="voucherInfoTitleRight" v-if="this.voucher.balance < this.voucher.value">Utilisation</p>
                <p class="voucherUsage">
                  <span v-for="(value, date) in JSON.parse(this.voucher.used)" :key="date">{{ timestampToDate(date) }} - {{ value }} CHF.</span>
                </p>
              </div>
            </div>
            <p class="voucherInfoTitle" v-if="voucher.balance > 0" style="margin-bottom: 0;">Montant à débiter</p>
            <p class="inputWrapper" v-if="voucher.balance > 0" style="margin-top: 5px;">
              <span><font-awesome-icon :icon="['fad', 'sack-dollar']" size="2x"/></span>
              <input id="voucherInput" type="number" placeholder="0.00" @input="calculateBalance"/>
              <span>CHF</span>
            </p>
            <p class="confirmCancelButtonsWrapper" style="margin: auto 0 auto auto" v-if="deduction !== 0">
              <font-awesome-icon :icon="['fad', 'times-circle']" size="2x" class="button" @click="cancelVoucherEdit" title="annuler"/>
              <font-awesome-icon :icon="['fad', 'check-circle']" size="2x" class="button" @click="deductVoucher" title="confirmer"/>
            </p>
            </div>
          <div v-else>
            <div class="voucherContent">
              <div class="flexColumn left">
                <p class="voucherInfoTitle">Montant à créditer</p>
                <p class="inputWrapper">
                  <span><font-awesome-icon :icon="['fad', 'sack-dollar']" size="2x"/></span>
                  <input id="voucherInput" type="number" placeholder="0.00" v-model="credit"/>
                  <span>CHF</span>
                </p>
              </div>
              <div class="flexColumn">
                <p class="voucherInfoTitle">Validité</p>
                <p class="inputWrapper">
                  <span><font-awesome-icon :icon="['fad', 'calendar-alt']" size="2x"/></span>
                  <datepicker v-model="validityDate" :language="fr" calendar-class="calendar"></datepicker>
                </p>
              </div>
            </div>
            <p class="confirmCancelButtonsWrapper" style="margin: 0 auto;" v-if="credit > 0">
              <font-awesome-icon :icon="['fad', 'times-circle']" size="2x" class="button" @click="cancelVoucherEdit" title="annuler"/>
              <font-awesome-icon :icon="['fad', 'check-circle']" size="2x" class="button" @click="sellVoucher" title="vendre"/>
            </p>
          </div>
					<div class="confirmVoucherEdit" v-if="confirmVoucherEdit">Le bon a été mis à jour!</div>
					<div class="confirmVoucherEdit red" v-if="errorVoucherEdit">Erreur de mise à jour</div>
					<div class="confirmVoucherEdit" v-if="confirmVoucherDelete">Le bon a été supprimé</div>
					<div class="confirmVoucherEdit red" v-if="errorVoucherDelete">Erreur suppression</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { QrcodeStream } from 'vue-qrcode-reader'
import Datepicker from 'vuejs-datepicker'
import fr from 'vuejs-datepicker/dist/locale'
require('locutus/php/var/is_float')

export default {
  name: 'Home',
  components: {
    QrcodeStream,
    Datepicker
  },
  data: function() {
    return {
      confirmDestroy: false,
      cameraLoading: false,
      cameraState: 'auto',
      scanResult: '',
      activeTab: 0,
      voucher: null,
      tabs: [
          'Scanner',
          'Vendre',
          'Créer',
          'Administrer'
      ],
			deduction: 0,
      credit: '',
			confirmVoucherEdit: false,
      confirmVoucherDelete: false,
      errorVoucherEdit: false,
      errorVoucherDelete: false,
      fr: fr,
      validityDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    }
  },
  onIdle() {
    this.$store.dispatch('logout')
  },
  computed: {
    isReady: function() {
      return this.$store.getters.isReady
    }
  },
	methods: {
		timestampToDate(sec) {
			if (sec > 99999999999) {
				sec = Math.round(sec / 1000)
			}
			const date = new Date(sec * 1000)
			return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`
		},
    askDestroyConfirm() {
      this.confirmDestroy = true
      let self = this
      setTimeout(function() {
        self.confirmDestroy = false
      }, 1000)
    },
    async destroyVoucher() {
      this.confirmDestroy = false
      let self = this
      await axios.delete(`/vouchers/${self.voucher.id}`, {
        headers: {
          'Authorization': `Bearer ${self.$store.state.token}`
        }
      }).finally(() => {
        self.$store.commit('deleteVoucher', self.voucher.code)
        self.confirmVoucherDelete = true
        setTimeout(self.hideConfirmOverlay, 2000)
      })
    },
		async deductVoucher() {
      if (this.deduction <= 0) {
        this.shakeError(document.querySelector('#voucherInput'))
        return
      }
			let usage = JSON.parse(this.voucher.used)
      if (this.voucher.balance - this.deduction <= 0) {
        usage[Date.now()] = this.voucher.balance
      } else {
        usage[Date.now()] = this.deduction
      }
			await axios.patch(`/vouchers/${this.voucher.id}`, {
				'balance': Math.max(this.voucher.balance - this.deduction, 0),
				'used': JSON.stringify(usage)
			}, {
				headers: {
					'Authorization': `Bearer ${this.$store.state.token}`
				}
			}).then(response => {
				this.$store.commit('receivedVoucher', response.data)
				this.confirmVoucherEdit = true
				setTimeout(this.hideConfirmOverlay, 2000)
			}).catch(() => {
        this.errorVoucherEdit = true
        setTimeout(this.hideConfirmOverlay, 5000)
			})
		},
    async sellVoucher() {
      if (this.credit <= 0) {
        this.shakeError(document.querySelector('#voucherInput'))
        return
      }

      await axios.patch(`/vouchers/${this.voucher.id}`, {
        'sellDate': Math.round(Date.now() / 1000),
        'validDate': Math.round(Date.parse(this.validityDate) / 1000),
        'value': parseInt(this.credit),
        'balance': parseFloat(this.credit),
        'seller': this.$store.state.user,
        'used': '{}'
      }, {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      }).then(response => {
        this.$store.commit('receivedVoucher', response.data)
        this.confirmVoucherEdit = true
        setTimeout(this.hideConfirmOverlay, 2000)
      }).catch((error) => {
        console.log(error.message)
        this.errorVoucherEdit = true
        setTimeout(this.hideConfirmOverlay, 5000)
      })
    },
		hideConfirmOverlay() {
			this.deduction = 0
			this.confirmVoucherEdit = false
			this.confirmVoucherDelete = false
			this.errorVoucherEdit = false
			this.errorVoucherDelete = false
			this.cancelVoucherEdit()
		},
		cancelVoucherEdit() {
			this.scanResult = ''
			this.voucher = null
			this.deduction = 0
      this.credit = ''
      document.querySelector('#voucherInput').value = ''
		},
    shakeError(input) {
      this.deduction = 0
      document.querySelector('#voucherInWork').classList.add('shake')
      input.disabled = true
      setTimeout(function (input) {
        input.value = ''
        input.disabled = false
        input.focus()
        document.querySelector('#voucherInWork').classList.remove('shake')
      }, 900, input)
    },
    calculateBalance(event) {
      const el = event.target
      let value = parseFloat(el.value)
      if (isNaN(value)) {
        el.value = el.value.slice(0, -1)
      } else if (value <= 0) {
        this.shakeError(document.querySelector('#voucherInput'))
        return
      }

      if (el.value !== this.deduction && el.value > 0) {
        document.querySelector('#voucherInfo').classList.add('attention')
        setTimeout(function() {
          document.querySelector('#voucherInfo').classList.remove('attention')
        }, 1200)
      }

			this.deduction = el.value || 0
    },
    async onInit (promise) {
      this.cameraLoading = true

      try {
        await promise
      } catch (error) {
        console.error(error)
      } finally {
        this.cameraLoading = false
      }
    },
    async onQrCode(result) {
      this.scanResult = result
      let target = document.getElementById('qrCodeInput')
      if (result.toUpperCase() in this.$store.state.vouchers) {
        target.classList.remove('codeNotFound')
        this.voucher = this.$store.state.vouchers[result.toUpperCase()]
				this.scanResult = ''
        setTimeout(function() {
          document.querySelector('#voucherInput').focus()
        }, 500)
      } else {
        target.classList.add('codeNotFound')
        this.voucher = null
      }
      this.pause()
      await this.timeout(500)
      this.resume()
    },
    paintBoundingBox (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },
    pause: function() {
      this.cameraState = 'off'
    },
    resume: function() {
      this.cameraState = 'auto'
    },
    timeout: function(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    onQRInput: function(event) {
      let value = event.target.value.trim()
      let found = 0
      let autoCode = ''
      for (const code in this.$store.getters.vouchers) {
        if (code.toLowerCase().startsWith(value.toLowerCase())) {
          found++
          autoCode = code
        }
        if (found > 1) {
          autoCode = ''
          break
        }
      }
      if (autoCode !== '') {
        event.target.value = autoCode
      }
      if (event.target.value.trim().length === 12) {
				this.onQrCode(event.target.value.trim())
      } else {
        event.target.classList.add('codeNotFound')
        this.voucher = null
				if (event.target.value.trim().length > 12) {
					this.scanResult = event.target.value.trim().slice(0, -1)
				}
      }
    }
  }
}
</script>

<style scoped>

.scannerContainer {
	width: 500px;
	margin: 15px auto;
	border: 2px solid var(--main-bg-color);
	border-radius: 5px;
}

.qrScanner {
}

.tabContainer {
  width: 50%;
  margin: 0 auto;
}

::placeholder {
  font-style: italic;
  color: var(--tertiary-text-color);
}

.codeNotFound {
  color: red;
}

.scannedVoucher {
	position: relative;
  min-height: 50px;
  width: 500px;
  margin: 15px auto;
  background-color: var(--hover-bg-color);
  box-sizing: border-box;
  padding: 15px;
  border-radius: 5px;
  font-size: 1.25em;
}

.voucherCode {
  box-sizing: border-box;
  padding: 5px;
	background-color: var(--tertiary-bg-color);
  border: 2px dashed var(--secondary-bg-color);
	color: var(--main-bg-color);
	font-weight: bold;
	text-transform: uppercase;
  width: 250px;
  text-align: center;
  margin: 0 auto;
}

.voucherInfo {
  font-weight: bold;
}

.voucherInfoTitle {
  color: var(--tertiary-text-color);
}

.voucherInfoTitleRight {
	color: var(--main-bg-color);
	font-size: 0.7em;
	font-weight: bold;
	text-align: right;
}

.voucherInfoRight {
	font-weight: bold;
	font-size: 0.7em;
	text-align: right;
}

.loadingIndicator {
  margin-top: 50px;
  width: 100%;
  text-align: center;
  color: var(--main-bg-color);
  font-size: 1.5em;
}

#qrCodeInput {
  text-align: center;
  text-transform: uppercase;
}

.deprecated {
	text-decoration: line-through;
	color: red;
	font-style: italic;
}

.confirmVoucherEdit {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	background-color: rgba(0, 0, 0, 0.75);
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	font-size: 2em;
}

.closeVoucher {
	position: absolute;
	top: 5px;
	right: 5px;
}

.destroyVoucher {
  position: absolute;
  top: 5px;
  left: 5px;
}

.voucherUsage {
	padding-left: 10px;
	box-sizing: border-box;
}

.voucherUsage > span {
	display: block;
	font-style: italic;
	font-size: 0.7em;
	text-align: right;
}

.red {
	color: red;
}

.center {
  text-align: center;
  vertical-align: center;
}

.voucherContent {
	display: flex;
	justify-content: space-evenly;
}

.flexColumn {
	width: 50%;
  margin: 5px;
}

.shake {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.attention {
  animation: attention 1s cubic-bezier(.36, .07, .19, .97) both;
  transform: scale(1);
  backface-visibility: hidden;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-3px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(4px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(8px, 0, 0);
  }
}

@keyframes attention {
  10%, 90% {
    transform: scale(1.05);
  }

  20%, 80% {
    transform: scale(0.9);
  }

  30%, 50%, 70% {
    transform: scale(1.12);
  }

  40%, 60% {
    transform: scale(0.82);
  }
}

</style>
