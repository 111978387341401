import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import IdleVue from 'idle-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Vue.component('font-awesome-icon', FontAwesomeIcon)
Chart.plugins.register(ChartDataLabels)

const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 5 * 60 * 1000,
  startAtIdle: false
})

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_BASEURL
Vue.config.productionTip = false

Date.prototype.getWeek = function() {
  const onejan = new Date(this.getFullYear(),0,1)
  const millisecsInDay = 86400000
  return Math.ceil((((this - onejan) /millisecsInDay) + onejan.getDay() + 1) / 7)
}

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
