<template>
  <div class="mainContainer">
    <div v-if="loading" class="loadingAnimation">
      <font-awesome-icon :icon="['fad', 'spinner']" size="10x" pulse/>
    </div>
    <div v-else>
      <div class="login">
        <form @submit.prevent="submit">
          <div class="inputWrapper">
            <span><font-awesome-icon :icon="['fad', 'user']" size="2x"/></span>
            <input id="username" type="text" placeholder="Nom d'utilisateur" v-model="form.username"/>
          </div>
          <div class="inputWrapper">
            <span><font-awesome-icon :icon="['fad', 'key']" size="2x"/></span>
            <input id="password" type="password" placeholder="Mot de passe" v-model="form.password"/>
          </div>
          <button type="submit">Connecter</button>
        </form>
        <font-awesome-icon :icon="['fad', 'spinner']" size="3x" pulse v-if="loggingIn" style="margin-top: 5px;"/>
        <p v-if="showLoginError && !loggingIn" class="error">Informations de connexion incorrectes</p>
        <p v-if="showNetworkError && !loggingIn" class="error">Erreur réseau</p>
        <p v-if="showDataError && !loading" class="error">Erreur de données</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
	name: 'Login',
  computed: {
    loggingIn: function() {
      return this.$store.getters.loggingIn
    },
    loading: function() {
      return this.$store.getters.loadingData
    }
  },
	data() {
		return {
			form: {
				username: '',
				password: ''
			},
			showLoginError: false,
			showNetworkError: false,
      showDataError: false
		}
	},
	methods: {
		...mapActions(['login', 'getVouchers']),
		async submit() {
      this.showDataError = false
      this.showLoginError = false
      this.showNetworkError = false
			const User = new FormData()
			User.append('username', this.form.username)
			User.append('password', this.form.password)
			User.append('grant_type', 'password')
      let self = this
			try {
        try {
          await this.login(User)
          this.showLoginError = false
        } catch (error) {
          if (error.message.toLowerCase() === 'network error') {
            this.showNetworkError = true
          } else {
            this.showLoginError = true
          }
          await this.$store.dispatch('logout')
        }
        this.$store.commit('loggingIn', false)
        await this.getVouchers()
        this.showDataError = false
        await this.$router.push('/')
      } catch (error) {
        if (!this.showLoginError) {
          this.showDataError = true
          setInterval(async function() {
            if (!self.showLoginError) {
              self.showDataError = false
            }
            await self.$store.dispatch('logout')
          }, 5000)
        }
      }
		}
	}
}
</script>

<style scoped>
.login {
	width: 750px;
	margin: 50px auto;
	padding: 15px;
	border-radius: 5px;
	background-color: var(--hover-bg-color);
	text-align: center;
}

input {
	text-align: left;
	margin-left: 15px;
}

.error {
	text-align: center;
	color: red;
	font-size: 1.5em;
}

.loadingAnimation {
  margin: 15px auto;
  text-align: center;
}
</style>
